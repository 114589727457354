export const categories = {
    maternity: {
        name: "maternity",
        album: "/maternity",
        p: "From the moment you find out you're growing a little person, your life will change in more ways than you can imagine. Maternity shoots can be as formal or informal as you'd like. These shoots can be done in my home studio or in an agreed outdoor location. The best time to do your maternity shoot is in the few weeks following the start of your third trimester."
    },
    newborn: {
        name: "newborn",
        album: "/newborn",
        p: "Babies grow so fast and it is wonderful to capture the first few days of their life and be able to keep those precious moments always. The best time to capture your little one is between 5 and 14 days old."
    },
    sitterSession: {
        name: "sitter session",
        album: "/sitter-session",
        p: "Babies grow so fast and reach so many amazing milestones so quickly and capturing them makes them so much more special. These sessions are for babies who can sit unaided but are not yet walking or crawling. I aim to capture their ever growing personality and beautiful smile while they're growing into a little independent person."
    },
    family: {
        name: "family",
        album: "/family",
        p: "Family portraits are always special to have. These photoshoots are fun and relaxed and can be enjoyed by the whole family. These can be done outdoors or in my home studio depending on your preferred style. These shoots create timeless memories and make lovely keepsakes or gifts."
    },
    cakeSmash: {
        name: "cake smash",
        album: "/cake-smash",
        p: "Cake smashes are a great way to celebrate and remember your little one's milestone birthday! These shoots are designed to create a fun filled light hearted session for you and your little one to enjoy and cherish."
    },
    engagement: {
        name: "engagement",
        album: "/engagement",
        p: "Once you've said YES to that special someone, you begin a very exciting journing together! Celebrating or announcing your engagement with a photoshoot is an amazing way to cherish the start of the next chapter in your life."
    }
};