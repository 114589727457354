import React from 'react';
import { NavLink } from 'react-router-dom';

export default function MainNav() {
    const mainMenuIitems = [
        { name: 'Home', to: '/', isDisplayed: true },
        { name: 'Gallery', to: '/gallery', isDisplayed: true },
        { name: 'About', to: '/about', isDisplayed: true },
        { name: 'Reviews', to: '/reviews', isDisplayed: true },
        { name: 'Faq', to: '/faq', isDisplayed: true },
        { name: 'Awards', to: '/awards', isDisplayed: true },
        { name: 'Contact', to: '/contact', isDisplayed: true }
    ];

    return (
        <nav className='main-nav'>
            <ul>
                {mainMenuIitems.map((item, itemIndex) =>
                    item.isDisplayed && <li key={itemIndex}>
                        <NavLink to={item.to}> {item.name} </NavLink>
                    </li>
                )}
            </ul>
        </nav>
    )
}
