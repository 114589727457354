import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';

import './css/App.css';
import './css/Sliders.css';
import './css/Styles.css';
import './css/Navigations.css';

import './css/Home.css';

import './css/Banner.css';

import logo from './img/logo_white.png';

import Home from './components/pages/Home';
import Gallery from './components/pages/Gallery';
import About from './components/pages/About';
import Reviews from './components/pages/Reviews';
import Faq from './components/pages/Faq';
import Awards from './components/pages/Awards.jsx';
import Contact from './components/pages/Contact';
import PrivacyPolicy from './components/pages/PrivacyPolicy';

import MainNav from './components/navigation/MainNav';
import MobileNav from './components/navigation/MobileNav.jsx';
import SocialNav from './components/navigation/SocialNav';
import SecondaryNav from './components/navigation/SecondaryNav';
import ThirdNav from './components/navigation/ThirdNav';

import { maternityPhotos } from './data/photos.js';
import { newbornPhotos } from './data/photos.js';
import { sitterSessionPhotos } from './data/photos.js';
import { familyPhotos } from './data/photos.js';
import { cakeSmashPhotos } from './data/photos.js';
import { engagementPhotos } from './data/photos.js';

import PhotoAlbum from './components/pages/PhotoAlbum';
import ScrollToTopOnNavigation from './components/ScrollToTopOnNavigation';

import { CiMenuBurger as HamburgerIcon } from "react-icons/ci";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import ScrollUp from './components/ScrollUp.jsx';
import Banner from './components/Banner.jsx';

function App() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isMobileNavDisplayed, setIsMobileNavDisplayed] = useState(false);

  function toggleHamburger() {
    setIsMobileNavOpen(!isMobileNavOpen);
  }

  function toggleMenu() {
    setIsMobileNavDisplayed(!isMobileNavDisplayed);
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 950) {
        setIsMobileNavDisplayed(false);
      } else if (window.innerWidth <= 950) {
        setIsMobileNavDisplayed(true);
      }
    };

    // Initial check when component mounts
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileNavDisplayed]);

  return (
    <Router>
      <ScrollToTopOnNavigation />
      <div className="App">
        <Banner />

        <ScrollUp />
        <header>
          <NavLink to='/'>
            <img src={logo} id='logo' alt='SPW Logo'></img>
          </NavLink>

          <MainNav />

          <HamburgerIcon onClick={() => { toggleHamburger(); toggleMenu() }} className={isMobileNavOpen ? "icon hamburger-icon icon-off" : "icon hamburger-icon"} />
          <CloseIcon onClick={() => { toggleHamburger(); toggleMenu() }} className={isMobileNavOpen ? "icon close-icon" : "icon close-icon icon-off"} />
        </header>

        <main>

          {!false && <MobileNav
            isMobileNavOpen={isMobileNavOpen}
            isMobileNavDisplayed={isMobileNavDisplayed}
            closeMobileMenu={() => setIsMobileNavOpen(false)}  // Pass the function as a prop
          />}

          <Routes>
            <Route path='*' element={<h1>Page not found</h1>} />

            <Route path='/' element={<Home />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/about' element={<About />} />
            <Route path='/reviews' element={<Reviews />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/awards' element={<Awards />} />
            <Route path='/contact' element={<Contact />} />

            <Route path='/privacy-policy' element={<PrivacyPolicy />} />

            <Route path='/gallery/maternity' element={<PhotoAlbum photos={maternityPhotos} albumName='Maternity photos' />} />
            <Route path='/gallery/newborn' element={<PhotoAlbum photos={newbornPhotos} albumName='Newborn photos' />} />
            <Route path='/gallery/sitter-session' element={<PhotoAlbum photos={sitterSessionPhotos} albumName='Sitter Session photos' />} />
            <Route path='/gallery/family' element={<PhotoAlbum photos={familyPhotos} albumName='Family photos' />} />
            <Route path='/gallery/cake-smash' element={<PhotoAlbum photos={cakeSmashPhotos} albumName='Cake Smash photos' />} />
            <Route path='/gallery/engagement' element={<PhotoAlbum photos={engagementPhotos} albumName='Engagement photos' />} />

          </Routes>

        </main>

        <footer>
          <h3>Covering South London</h3>
          <div id='footer-info'>

            <div id='first-details' className='details'>
              <SecondaryNav />
            </div>

            <div id='second-details' className='details'>
              <ul>
                <li>South London</li>
                <li>North Cheam</li>
                <li>Cheam Village</li>
                <li>South Cheam</li>
                <li>Sutton Town Centre</li>
                <li>North Sutton</li>
                <li>Carshalton</li>
                <li>Belmont</li>
                <li>Sutton Common</li>
                <li>Rosehill</li>
              </ul>
            </div>

            <div id='third-details' className='details'>
              <ThirdNav />
            </div>

          </div>

          <SocialNav />

          <div className='copyright'>
            <span>© {new Date().getFullYear()} Sparkling Willow Photography</span>
            <a href="https://karolyhornyak.co.uk" target="_blank" rel="noopener noreferrer">Website made by Karoly Hornyak</a>
          </div>
        </footer>
      </div>
    </Router >
  );
}

export default App;
